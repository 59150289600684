<template>
  <div v-if="env.toLowerCase() !== 'production' || validated" class="user-login-container">
    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>
            Selecionar usuário
          </label>
          <multiselect
            autocomplete="off"
            v-model="user"
            track-by="id"
            label="name"
            placeholder="Selecionar"
            :options="users"
            :internal-search="true"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="true"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="option" slot-scope="{ option }">
              <div>
                <div>{{ option.name }}</div>
                <div class="role">{{ option.role }}</div>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <div class="single-label">
                <div>{{ option.name }}</div>
                <div class="role">{{ option.role }}</div>
              </div>
            </template>
            <template slot="noOptions">
              <div>Selecione uma clínica</div>
            </template>
            <template slot="noResult">
              <div>Nenhum usuário encontrado.</div>
            </template>
          </multiselect>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group>
            <label>
              Senha
            </label>
            <div class="input-password">
              <input
                id="password"
                :type="!showPassword ? 'password' : 'text'"
                v-model="password"
                @keyup.enter="signIn"
                class="form-control"
              />
              <EyeSlash
                class="eye"
                v-if="showPassword == false"
                @click="showPassword = !showPassword"
              />
              <Eye
                class="eye"
                v-if="showPassword == true"
                @click="showPassword = !showPassword"
              />
            </div>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-button
            class="button"
            variant="primary"
            size="lg"
            :disabled="(!user || !clinic)"
            @click="signIn"
          >
            Entrar
          </b-button>
        </div>
      </div>
  </div>
  <div class="pin-content" v-else>
    <div><b>Funcionalidade bloqueada</b>, digite o <b>PIN</b> de desbloqueio</div>
    <div>
      <b-form-input
        autofocus
        autocomplete="off"
        class="pin-input"
        type="password"
        v-model="pin"
        :maxlength="4"
        @keyup.enter="validatePin"
      />
    </div>
  </div>
</template>
<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Eye from '@/assets/icons/eye.svg'
import EyeSlash from '@/assets/icons/eye-slash.svg'

import {
  getCurrentUser,
  setAccessToken,
  setRefreshToken,
  setCurrentUser,
  setCurrentClinic,
  setPermissions,
  userHasPermission
} from '@/utils/localStorageManager'

export default {
  components: { ChevronDown, Eye, EyeSlash },
  props: {
    clinic: Object,
  },
  mounted() {

  },
  data() {
    return {
      env: process.env.NODE_ENV ?? '',
      validated: false,
      pin: null,
      currentUser: getCurrentUser(),
      user: null,
      password: null,
      users: [],
      showPassword: false,
    }
  },
  methods: {
    validatePin() {
      this.pin === '2019' ?
        (this.validated = true) :
        this.$toast.warning('PIN inválido')
    },
    signIn() {
      if (!this.user) return
      const props = {
        email: this.currentUser.email,
        password: this.password,
        targetId: this.user.user_id,
        clinicId: this.clinic.id
      }
      this.api.superUserLogin(props)
        .then(({ data }) => {
          const clinic = data.user.clinics.find(el => el.id === this.clinic.id)
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          setCurrentUser(data.user);
          setCurrentClinic(clinic);
          setPermissions(data.user.permissions);

          if(clinic.role === 'DOCTOR' || clinic.role === 'DOCTOR_MANAGER' || !userHasPermission('FpAgen1')){
            window.location.href = '/visao-geral'
          } else {
            window.location.href = '/agenda'
          }
        })
        .catch(err => this.$toast.error(err.message))
    },
    getClinicUsers() {
      this.api.getPeopleFromClinic(this.clinic.id)
        .then(({ data }) => {
          this.users = data
        })
    }
  },
  watch: {
    clinic() {
      this.clinic && this.getClinicUsers()
    },
    pin() {
      if (this.pin.length === 4)
        this.validatePin()
    }
  }
}
</script>
<style lang="scss" scoped>
.user-login-container {
  padding: 20px;
  .single-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
  }
  .role {
    font-size: 12px;
    color: var(--blue-500);
  }
  .button {
    width: 100%;
    height: 38px;
    margin-top: 28px;
  }
}
.pin-content {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .pin-input {
    width: 200px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 10px;
  }
}
</style>
